import { Check, Plus } from "lucide-react";

import { Toggle } from "../ui/toggle";

export function ToggleButton({
  label,
  checked,
  onCheckedChange,
  disableWhenChecked = true,
}: {
  label: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  disableWhenChecked?: boolean;
}) {
  return (
    <Toggle
      variant="outline"
      pressed={checked}
      onPressedChange={onCheckedChange}
      className="gap-2 disabled:opacity-75 data-[state=off]:border-slate-200 data-[state=on]:border-green-200 data-[state=off]:bg-slate-100 data-[state=on]:bg-green-100 data-[state=off]:text-slate-900 data-[state=on]:text-green-900"
      disabled={disableWhenChecked && checked}
    >
      {checked ? <Check className="size-4" /> : <Plus className="size-4" />}
      <span>{label}</span>
    </Toggle>
  );
}
